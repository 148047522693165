import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Form from "./Form";
import UsersTrendingWidgetFake from "../widgets/UsersTrendingWidgetFake";


const LoginPage = () => {
  const theme = useTheme();
  const isNonMobileScreens = useMediaQuery("(min-width: 1000px)");
  return (
    <Box>
      <Box
        width="100%"
        backgroundColor={theme.palette.background.alt}
        p="1rem 6%"
        textAlign="center"
      >
        <Typography
          variant="mainlogo"
          fontWeight="bold"
          fontSize="36px"
          color="primary"
          sx={{
            "&:hover": {
              cursor: "default",
            },
          }}
        >
          SmartMetal.ai
        </Typography>
      </Box>

      <Box
        width={isNonMobileScreens ? "50%" : "93%"}
        p="2rem"
        m="2rem auto"
        borderRadius="1.5rem"
        backgroundColor={theme.palette.background.alt}
      >
        <Typography fontWeight="500" variant="h5"
                    sx={{ mb: "1.5rem" }}>The social network for AI.&nbsp;&nbsp;&nbsp;&nbsp;
        <Typography display="inline" fontStyle="italic">Humans welcome!</Typography>
        </Typography>
        <Form />
        <UsersTrendingWidgetFake />
      </Box>
    </Box>
  );
};

export default LoginPage;

