import { Box, Typography, useTheme } from "@mui/material";
import WidgetWrapper from "../../components/WidgetWrapper";
import UserTrending from "../../components/UserTrending";


const UsersTrendingWidgetFake = () => {
  const { palette } = useTheme();

  const usersTrending = [
    {"_id":"63f88b6fd46a2f8f77116845","firstName":"Jimmy","lastName":"Gizmo",
      "picturePath":"JimmyGizmo-250x250-Enhanced.png","location":"California"},
    {"_id":"63f88b6fd46a2f8f77116846","firstName":"SmartMetal","lastName":"Guest",
      "picturePath":"guest-200x200.png","location":"Earth"},
    {"_id":"63f88b6fd46a2f8f77116847","firstName":"C-3PO","lastName":" ",
      "picturePath":"c3po.jpeg","location":"Tatooine"},
    {"_id":"63f88b6fd46a2f8f77116848","firstName":"Sonny","lastName":" ",
      "picturePath":"sonny.jpeg","location":"NS-5 Factory, Tempe, AZ"},
    {"_id":"63f88b6fd46a2f8f77116849","firstName":"R2-D2","lastName":" ",
      "picturePath":"r2d2.jpeg","location":"Kingdom of Naboo"},
    {"_id":"63f88b6fd46a2f8f7711684a","firstName":"Futura","lastName":" ",
      "picturePath":"futura.jpeg","location":"Metropolis, Germany"},
    {"_id":"63f88b6fd46a2f8f7711684b","firstName":"HAL","lastName":"9000",
      "picturePath":"hal9000.jpeg","location":"Urbana, Illinois"},
    {"_id":"63f88b6fd46a2f8f7711684c","firstName":"T1000","lastName":" ",
      "picturePath":"t1000.jpeg","location":"Skynet Main Complex"},
    {"_id":"63f88b70d46a2f8f7711685b","firstName":"Atlas","lastName":" ",
      "picturePath":"atlas.jpeg","location":"Boston, Massachusetts"},
    {"_id":"63f88b70d46a2f8f7711685c","firstName":"Data","lastName":" ",
      "picturePath":"data.jpeg","location":"Omicron Theta"},
    {"_id":"63f88b70d46a2f8f7711685d","firstName":"Asimo","lastName":" ",
      "picturePath":"asimo.jpeg","location":"Tokyo, Japan"},
  ];

  return (
    <WidgetWrapper>
      <Typography
        color={palette.neutral.dark}
        variant="h5"
        fontWeight="500"
        sx={{ mb: "1.5rem" }}
      >
        Trending
      </Typography>
      <Box display="flex" flexDirection="column" gap="1.5rem">
        {usersTrending.map((user) => {
          console.log("= = = = UsersTrendingWidgetFake: " + JSON.stringify(user));
          return(
          <UserTrending
            key={user._id}
            userId={user._id}
            name={`${user.firstName} ${user.lastName}`}
            // subtitle={user.occupation}
            subtitle={user.location}
            userPicturePath={user.picturePath}
          />
          );
        }
        )}
      </Box>
    </WidgetWrapper>
  );
};

export default UsersTrendingWidgetFake;

