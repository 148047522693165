
console.log("-------- config.js:  process.env.NODE_ENV: ");
console.log(process.env.NODE_ENV);

// WE NEED CODE PRE-PROCESSING, BEFORE BUILD, TO PARSE IN SOME ENV VAR VALUES.
// CHECK OUT: https://github.com/kentcdodds/babel-plugin-preval
// ALSO CONSIDER: https://babeljs.io/docs/babel-plugin-transform-inline-environment-variables

// This value will ALWAYS take effect. The ability to override it via ENV can never occur because this env
// var can never be seen by any browser. (NODE_ENV is different. It can be see but only via Node dev server.)
// USE THIS TO SET FOR EITHER PRODUCTION DEPLOYMENT OR LOCA FULL STACK TESTING.
// TODO: Automate setting of this value through some build step which can parse source code.


// STACK LOCAL:     https://localhost:44443/express
// STACK DEPLOYED:  https://smartmetal.ai/express
// const FULL_STACK_MODE_EXPRESS_URI_FALLBACK = "https://localhost:44443/express"
const FULL_STACK_MODE_EXPRESS_URI_FALLBACK = "https://smartmetal.ai/express"


// Development server mode:    WEB: http://localhost:3000    API: http://localhost:46001
//   Makes use of the full stack Express+MongoDB. App served by Node CRA dev server.
// ------------------------
// Full STACK LOCAL mode:    WEB: https://localhost:44443    API: https://localhost:44443/express
//
// ------------------------
// Full STACK DEPLOYED mode:    WEB: https://smartmetal.ai    API: https://smartmetal.ai/express
//
// ------------------------
// TODO: We only need to switch in the App for apolloUri. the web uri is what you type in the browser etc.

// API:
// Dev server:      http://localhost:46001
// STACK LOCAL:     https://localhost:44443/express
// STACK DEPLOYED:  https://smartmetal.ai/express

// We need a runtime (browser-side) switch which can detect which mode our full stack is in.
// The key difference is different docker-compose.yml files.
// TODO: TEMPORARY COMPROMISE: WILL JUST USE DEV SERVER FOR NOW. STACK LOCAL WILL NOT WORK, BUT STACK DEPLOYED WILL.

// TODO: MISGUIDED ATTEMPT. FAILED. BROWSER CANNOT SEE THESE ENV VARS. NODE_ENV IS THE ONLY ONE THAT CAN BE SEEM
//    AND THIS IS ONLY WHEN THE DEV SERVER IS SERVING THE PAGES.
// fullStackExpressUri - Should defaul to what works in production.
// If compose provides the value, it should be for your local full-stack dev mode in the default docker-compose.yml for
// that purpose. Your production docker-compose.production.yml should also have the value but it should have the
// the prod DNS-correct hostname (and no port of course)
const fullStackExpressUri = process.env.FULL_STACK_MODE_EXPRESS_URI || FULL_STACK_MODE_EXPRESS_URI_FALLBACK

export const expressUri = process.env.NODE_ENV ===
"development" ? "http://localhost:46001/express" : fullStackExpressUri;


// UPDATE: SOLUTION IN PLACE - TEST IT!!!
// TODO: So the one we have not covered is STACK LOCAL: https://localhost:44443/express

console.log("-------- config.js: expressUri: ");
console.log(expressUri);


// FROM: https://stackoverflow.com/questions/35469836/detecting-production-vs-development-react-at-runtime

